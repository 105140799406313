.timer {
    margin-top: 10%;
    border: 1px solid #fff;
    padding: 30px 30px;
    width: 350px;
    background-color: black;
}
.timer > h2 {
    color: #fff;
    text-align: center;
}
.timerSpan {
    display: flex;
    justify-content: center;
}
.timerSpan > span {
    font-size: 35px;
    color: #fff;
    font-weight: 500;

}
.timerBtn {
    display: flex;
    justify-content: center;
}
.timerBtn > button {
    padding: 7px 15px;
    margin-left: 15px;
    outline: none;
    border: none;
    font-size: 17px;
    border-radius: 3px;
    background-color: #222222;
    color: #fff;
}
.timerBtn > button:hover {
    opacity: .5;
    transition: .5;   
}
form {
    color: #fff;
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
}
form > input {
    padding: 5px 5px;
    margin-top: 20px;
    margin-bottom: 25px;
    outline: none;
    background-color: #222222;
    border-radius: 3px;
    color: #fff;
    border: 1px solid #fff;
}
form > button {
    padding: 5px 5px;
    width: 180px;
    margin: auto;
    background-color: #222222;
    border: none;
    color: #fff;
    font-size: 18px;
    border-radius: 3px;
}
form > button:hover {
    opacity: .5;
}
